import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localstorage에 저장
import loadingReducer from "./loading/loadingSlice.js";
import userReducer from "./user/userSlice.js";
import cbReducer from './cb/CBSlice.js'

const persistConfig = {
  key: "root",
  storage,
};

export const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    loading: loadingReducer,
    user: userReducer,
    cb: cbReducer
  })
);
