import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Layout from "layout/user/Layout";

export default function ProtectedRoute() {
  const { userId } = useSelector((state) => state.user);

   //로그인이 안 된 경우
   //0912
   if (userId === null) {
     return <Navigate to="/login" replace={true} />;
   } 

  return (
    <div>
      <Layout />
    </div>
  );
}
