import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';


import { menuData } from './menuData'
import { setOpenSubMenu  } from './sidbarFuns'

// CSS파일 위치 : assets/css/sidebar.css
export default function Sidebar({ handleHambugerMenu, isHambugerCollapsed }) {
  const loginUserRoles  = useSelector((state) => state.user.roles );
  const topMenuIcons = [ "las la-coins", "las la-users-cog" ];

  return (
    <div className='position-relative'>
    <div
      id="main-sidebar_bg"
      className={isHambugerCollapsed ? '' : 'd-none'}
      onClick={handleHambugerMenu}
     ></div>
     
     <nav
        className="main-sidebar"
        // onMouseEnter={() => handleMenuMousEnter(isHambugerCollapsed)}
        // onMouseLeave={() => handleMenuMousLeave(isHambugerCollapsed)}
     >

       <div id='log-box' className={`d-flex justify-content-center align-items-center`}>
          <Link to={'/'} className='d-flex '>
            <span className={"d-block customer-logo"}><img src="/assets/img/logo/vicgame-logo.png" alt="border" /></span>
          </Link>
          <span className="d-flex align-items-center justify-content-center sidebar-toggle-icon" onClick={handleHambugerMenu}>
              <i className={`las ${isHambugerCollapsed ? "la-angle-double-right": "la-angle-double-left"} color-white font-18 font-bold`}></i>
          </span>
       </div>
       <div className={`menu-bar `}>
           <div className={`menu`}>
                <ul className="menu-links">
                   {!!menuData && menuData.map((menu, idx) => (
                      <div key={idx} className='top-meun-wrap'>
                        {/* Top Menu */}
                        <li
                          id={`top-item-${idx}`}
                          className={`
                          ${menu.depth2 === undefined ? 'no_submenu ' : ''}  
                          ${loginUserRoles.includes(menu.role) ? '' : 'd-none'}  
                          cursor-pointer top-menu nav-link position-relative d-flex align-items-center `}
                          onClick={(e) => setOpenSubMenu(e, idx)}
                        >
                            <span className="icon d-inline-block">
                              <i className={`${topMenuIcons[idx]} font-24 color-mid`}></i>
                            </span>
                            <span className="text nav-text">{ menu.name }</span>
                        </li>
              
                        {/* Sub Menu */}
                        <ul className='sub-menu' id={`sub-item-${idx}`}>
                          {menu.depth2 && menu.depth2.map((subMenu, i) => (
                            <li key={i}  className='sub-link'>
                              <NavLink
                                to={  subMenu.path }
                                className={({ isActive, isPending }) => (
                                    isActive
                                    ? "now"
                                    : isPending
                                    ? "pending"
                                    : "link"
                                  )}
                              >
                                <div className='d-flex align-items-center'>
                                  <span className="nav-text">
                                    <span className='caret-icon'/> 
                                    {/* <FontAwesomeIcon icon={faCaretRight}  className='caret-icon'/>  */}
                                    { subMenu.name }
                                  </span>
                                </div>
                              </NavLink>
                            </li>
                          ))} 
                        </ul>
                      </div>
                     ))} 
                  </ul>
           </div>
            
       </div>
     </nav>
   </div>
  );
}

