import { createSlice } from "@reduxjs/toolkit"; // reducer 로직과 action을 단순화 시킴
import { removeJWT } from "./userApi";

import {
  loginThunk,
} from "./userThunk";

let _state = {
  id: null,
  an8: null,
  userId: null,
  corpName: null,
  roles: null,
  latestLoginAt: null
};

let initialState = {
  id: null,
  an8: null,
  userId: null,
  corpName: null,
  roles: null,
  latestLoginAt: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogOutForce(state) {
      for (let prop in _state) {
        state[prop] = _state[prop];
      }
      removeJWT();
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, { payload }) => {
        
        for (let prop in payload) {
          if (prop === "roles") {
            let role =  payload[prop].slice(1, -1).split(", ") 
            state[prop] = role;
          } else {
            state[prop] = payload[prop];
          }
        }

        return state;
      })
      .addCase(loginThunk.rejected, (state, { payload }) => {
        state.error = payload.error;
        return state;
      })
      .addDefaultCase((state) => {
        return state;
      });
  },
});

export const { userLogOutForce } = userSlice.actions;
export default userSlice.reducer;
