import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "util/customAxios";
import { userLogOutForce } from "./userSlice";
import { setJWTLocalStorage } from "./userApi";
import { alertFun } from "store/reducer/cb/CBApi"

export const loginThunk = createAsyncThunk(
  "user/login",
  async ({ navigate, loginData }, { dispatch, rejectWithValue }) => {
    try {
      const { userId, password } = loginData;

      let res = await http.post("/api/sign-in", {
        userId,
        password
      });

      if(res.data.data.token !== null) {
        setJWTLocalStorage(res.data.data.token)
        return res.data.data.user;
      }
      
    } catch (err) {
      alert(err.response.data.message); // 아이디 또는 비밀번호 오류입니다 알림창
      navigate("/login")
      dispatch(userLogOutForce());
    }
  }
);


export const logoutThunk = createAsyncThunk(
  "user/logout",
  async ({  navigate }, { dispatch, rejectWithValue }) => {
    try {
      await http.get("/api/sign-out");

      dispatch(userLogOutForce());
      navigate("/login");
    } catch (err) {
      alertFun(err);
      dispatch(userLogOutForce());
      // return rejectWithValue(err);
    }
  }
);


// 접근 권한 설정 테스트
export const getOneUserThunk = createAsyncThunk(
  "user/login",
  async ({ navigate }, { dispatch, rejectWithValue }) => {
    try{
      const userId = "김양현"
      let res = await http.get(`/api/user/someuser?userId=${userId}`);
  
    } catch(err) {
      alert(`[${err.response.data.type}] : `+ err.response.data.message)
      navigate("/login")
    }
  }
);
