import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigation,
  useNavigate,
} from "react-router-dom";
import Header from "layout/header/Header";
import Sidebar from "layout/sidebar/Sidebar";
import Footer from 'layout/footer/Footer';
import { setAxiosInterceptor } from "util/customAxios";
import { setJWTHeader } from "store/reducer/user/userApi";
export default function Layout() {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // 햄버거 메뉴 핸들링
  const [isHambugerCollapsed, setHambugerMenuOpen] = useState(false);


  const handleHambugerMenu = () => {
    setHambugerMenuOpen(!isHambugerCollapsed);
    
    // 열려 있는 SubMenu 닫기
    const topMenus = document.getElementsByClassName("mm-collapse");
    
    for (let i = 0; i < topMenus.length; i++) {
      topMenus[i].classList.remove("mm-show");
    }
    const { depth1, depth2  } = activeTabs;
    
    if(isHambugerCollapsed) {
      // 햄버거 메뉴가 open일 때, 이전에 클릭한 메뉴 활성화 시키기
      if(depth1 != null) {
        // 아코디언 메뉴 확장
        const subMenuLi = document.getElementById(`depth1-item-${depth1}`);
        subMenuLi.classList.toggle('mm-show')
      }
      else if(depth2 != null) {
        const subMenuLi = document.getElementById(`depth2-item-${depth1}${depth2}`);
        const triangleIcon = document.getElementsByClassName(`depth2-${depth1}${depth2}-expand-icon`);
        
        triangleIcon[0].classList.toggle('open')
        subMenuLi.classList.add('mm-show')
      }
    }

  }

  const [ activeTabs, setActiveTabs  ] = useState({
    depth1: null,
    depth2: null,
    depth3: null,
  })

  // axios 요청/응답에 따른 Loading 컴포넌트 보임/숨김 처리
  useEffect(() => {
    setAxiosInterceptor(dispatch, navigate);
  }, [dispatch, navigate]);

  // 첫 랜더링 시 스크롤 위치 상단 이동
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  
  useEffect(() => {
    setJWTHeader();
  }, []);

  return (
    <div
      className={`
        ${
          isHambugerCollapsed
            ? "sidebar-wrap sidebar-collapse sidebar-open"
            : "sidebar-wrap sidebar-closed"
        }
      `}
    >
      <Header handleHambugerMenu={handleHambugerMenu} />
      <div>
        <Sidebar
          handleHambugerMenu={handleHambugerMenu}
          isHambugerCollapsed={isHambugerCollapsed}
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
        />
        <div
          id="contents-container"
          className={`  
            ${navigation.state === "loading" ? "loading" : ""}
         `}
        >
          <div className="mg-t15 pd-l25 pd-r25">
            <Outlet />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
