import styles from './FallBack.module.css';
import BarLoader from "react-spinners/BarLoader";

const override = {
  display: "block",
  margin: "0 auto",
};

export default function FallBack() {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <h5 className='text-center color-light font-12 mg-b10'>Loading...</h5>
        <BarLoader
          color="#555"
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          speedMultiplier={1}
        />
      </div>
    </div>
  );
}

