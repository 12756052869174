
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

export default function Footer() {

    return (
       <footer id={styles.footer} >
         <div>
               <div className={`${styles.footer_guide}`}>
                  <div className="container ">
                     <ul>
                        <li><Link target='_blank' to="https://wilus.com">회사 소개</Link></li>
                        {/* <li><Link to="/"><b>개인정보취급방침</b></Link></li>
                        <li><Link to="/">이용약관</Link></li>
                        <li><Link to="/">이용가이드</Link></li> */}
                     </ul>
                  </div>
               </div>
               <div className='bg-gray'>
                  <div className={`container `}>
                     <div  className={` ${styles.footer_wrap}`}>
                        {/* <div className={`${styles.logo} `}>
                           <img src="/img/tnt-logo-letter.jpg" alt="tnt-logo" />
                        </div> */}
                        <div className=''>
                           <div >
                              <div className={styles.footer_txt_box}>
                                 {/* <b>Wilus</b> */}
                                 <span><span className="eng-700">Location &nbsp;:</span>&nbsp; 서울특별시 서초구 반포대로 86 신원빌딩 6층</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                 <span><span className="eng-700">Call</span> : 02-581-1145</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                 <span><span className="eng-700">Fax</span> : 02-581-1136</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                 <span><span className="eng-700">Email</span> : css@wilus.com</span>
                              </div>
                           </div>
                           <div className={`${styles.copylight}`}>
                              <span className='d-inline-block'>모든 컨텐츠의 무단복제 및 재판매를 금지합니다.</span> 
                              <span className='d-block'>Copyright(c) 2023~ by Wilus. All Rights Reserved. Designed by <Link className='txt-underline' to="https://wilus.com/" target='_blank'>wilus.com </Link></span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
         </div>
       </footer>
    )
  }