import axios from "axios";
import { setJWTHeader, removeJWT } from "store/reducer/user/userApi";


// axios 기본 설정
const instance = axios.create();
const JSESSIONID = window.localStorage.getItem("JSESSIONID");
instance.defaults.baseURL = process.env.REACT_APP_API_URL;
instance.defaults.withCredentials = true;
instance.defaults.headers.common["Authorization"] = JSESSIONID;

// axios 요청/응답에 따른 Loading 컴포넌트 보임/숨김 처리
export const setAxiosInterceptor = (dispatch, navigate) => {
  // 1. 요청 인터셉터
  instance.interceptors.request.use(
    async function (config) {
      setJWTHeader(); 
      
      return config;
    },
    function (error) {
        // 오류 요청을 보내기전 수행할 일
        return Promise.reject(error);
    });

  // 2. 응답 인터셉터
  instance.interceptors.response.use(
    async function (response) {
        // 응답 데이터를 가공
        return response;
    },
    function (error) {
      //  removeJWT();
        // 오류 응답을 처리
        return Promise.reject(error);
    });
};

export default instance;
