export const setOpenSubMenu = (e, idx) => {
    // setActiveTopMenu(idx)
    // e.preventDefault();

    // 서브 메뉴 li를 보임/숨김 처리
    // const topMenuLi = document.getElementById(`top-item-${idx}`);
    const subMenuLi = document.getElementById(`sub-item-${idx}`);
    subMenuLi.classList.toggle('active')

    // 삼각 화살표 위/아래
    const topMenuTriangle = document.getElementsByClassName('top-menu');
    topMenuTriangle[idx].classList.toggle('active')
    topMenuTriangle[idx].classList.toggle('menu_active')
  }

  export  const handleMenuMousEnter = (isHambugerCollapsed) => {
    const mediaQ = window.matchMedia("screen and (max-width: 990px)");
    if(isHambugerCollapsed && !mediaQ.matches) {
      const sidebar = document.getElementsByClassName("sidebar-wrap")
      sidebar[0].classList.remove("sidebar-open")
      sidebar[0].classList.remove("sidebar-collapse")
      sidebar[0].classList.add("sidebar-closed")
    }
  }

  export const handleMenuMousLeave = (isHambugerCollapsed) => {     
     const mediaQ = window.matchMedia("screen and (max-width: 990px)");
     if(isHambugerCollapsed && !mediaQ.matches) {
      const sidebar = document.getElementsByClassName("sidebar-wrap")
      sidebar[0].classList.remove("sidebar-closed")
      sidebar[0].classList.add("sidebar-open")
      sidebar[0].classList.add("sidebar-collapse")
    }

  }