import { configureStore } from "@reduxjs/toolkit";
import { persistedReducer } from "./reducer/index.js";

export default configureStore({
  reducer: persistedReducer, // root reducer 만들기
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
