export const menuData = [
  {
    name: "현금영수증",
    path: "/",
    role: "CB",
    depth2: [
      {
        name: "개별발급",
        path: "/cashbill/write/one",
      },
      {
        name: "일괄 승인작성",
        path: "/cashbill/write/normal",
      },
      {
        name: "일괄 취소작성",
        path: "/cashbill/write/cancel",
      },
      {
        name: "일괄발급",
        path: "/cashbill/temp",
      },
      {
        name: "발급결과조회",
        path: "/cashbill/search",
      },
      {
        name: "발급결과로그",
        path: "/cashbill/log",
      },
    ],
  },
  {
    name: "사용자관리",
    path: "/",
    role: "ADMIN",
    depth2: [
      {
        name: "사용자조회",
        path: "/user/search",
      },
    ],
  },
];


export const breadCrumb = {

    "일괄발급" :"/cashbill/register/one",
    "일괄승인작성" : "/cashbill/write/normal",
    "일괄취소작성" : "/cashbill/write/cancel",
    "개별발급" : "/cashbill/write/one",
    "발급결과조회" : "/cashbill/search",
    "마이페이지" : "/mypage",
    // 사용자 관리
    "사용자조회" : "/user/search",
}