import { createSlice } from "@reduxjs/toolkit"; // reducer 로직과 action을 단순화 시킴


let initialState = {
  groupSubmitId : "",
  submitIds : []
};

const CBSlice = createSlice({
  name: "CBSlice",
  initialState,
  reducers: {
    setGroupSubmitIds(state, { payload }) {
        state.groupSubmitId = payload;
        return state;
    },
    setSubmitIds(state, { payload }) {
        const updatedSubmitIds = [...state.submitIds, payload];
        state.submitIds = updatedSubmitIds;

        return state;
    },
  },
  extraReducers: (builder) => {
    builder
    //   .addCase(loginThunk.fulfilled, (state, { payload }) => {

    //     return state;
    //   })
    //   .addCase(loginThunk.rejected, (state, { payload }) => {
    //     state.error = payload.error;
    //     return state;
    //   })
      .addDefaultCase((state) => {
        return state;
      });
  },
});

export const { setGroupSubmitIds, setSubmitIds } = CBSlice.actions;
export default CBSlice.reducer;
