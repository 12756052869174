import http from "util/customAxios";
import { removeJWT } from "../user/userApi";


export const cbOneRegistOrRevokeIssue = async (tradTyp, cashReceipt, corpNum) => { 
  try {

    let res;
    if(tradTyp === "승인거래") {
      res = await http.post(`/api/cashbill/register/one/${corpNum}`, {
        submitId: "", 
        tradeType: "승인거래",
        receiptNum: cashReceipt.receiptNum,
        totalAmount: cashReceipt.totalAmount,
        originConfirmNum: "",
        originTradeDate: "",
        creatorId: ""
      });
  
    } else if (tradTyp === "취소거래") {
      res = await http.post(`/api/cashbill/revoke/one/${corpNum}`, {
        submitId: "", 
        tradeType: "취소거래",
        receiptNum: cashReceipt.receiptNum,
        totalAmount: "",
        originConfirmNum: "",
        originTradeDate: "",
        creatorId: ""
      });
    }
  
    return res.data;

  } catch (err) {
    console.log("cbOneRegistOrRevokeIssue Err : ", err)
    alertFun(err);
  }

}


export const getCBRecord = async (corpNum, setData) => {
    try {
      const res = await http.get(`/api/cashbill/allInfo/${corpNum}`);

      const modifiedData = res.data.data.map(item => {
        let totalAmount = item.totalAmount;
        const supply_amount = Math.round(totalAmount / (1 + 0.10));
        const vat_amount = totalAmount - supply_amount;

        return { ...item, supply_amount, vat_amount};
      });
      
      setData(modifiedData);
    } catch (err) {
      console.log("getCBRecord Err : ", err)
      alertFun(err)
    }
};

export const getCBRecordSearch = async (corpNum, searchDto, setCBRRecord) => {
  try {
    const { tradeType, stateCode, fromDate, toDate } = searchDto;
    const res = await http.get(`/api/cashbill/allInfo/search/${corpNum}?tradeType=${tradeType}&stateCode=${stateCode}&fromDate=${fromDate}&toDate=${toDate}`);

    const modifiedData = res.data.data.map(item => {
      let totalAmount = item.totalAmount;
      const supply_amount = Math.round(totalAmount / (1 + 0.10));
      const vat_amount = totalAmount - supply_amount;

      return { ...item, supply_amount, vat_amount};
    });

    setCBRRecord(modifiedData);
    return modifiedData;

  } catch (err) {
    console.log("getCBRecordSearch Err : ", err)
    alertFun(err)
  }
};



export const getCBLogSearch = async (searchDto, setData) => {
  try {
    const { corpNum, submitId, fromDate, toDate } = searchDto;
    const res = await http.get(`/api/cashbill/submit/log/${corpNum}?submitId=${submitId}&fromDate=${fromDate}&toDate=${toDate}`);
    
    setData(res.data.data);
  } catch (err) {
    console.log("getCBLog Err : ", err)
    alertFun(err)
  }
};

  
  
export const getCBUplaod = async (corpNum, setData) => {
  try {

    const res = await http.get(`/api/cashbill/upload/temp/${corpNum}`);
    console.log("getCBUplaod res ===== ", res)
    

    const modifiedData = res.data.data.map(item => {
      const totalAmount = item.totalAmount;
      const supply_amount = Math.round(totalAmount / (1 + 0.10));
      const vat_amount = totalAmount - supply_amount;
      return { ...item, supply_amount, vat_amount};
    });
    
    setData(modifiedData);
  } catch (err) {
    console.log("getCBUplaod Err : ", err)
    alertFun(err);
  }
};

export const getReceiptInfo = async (searchInfo, receiptNum, setData, setSuccess) => {
  try {
    const res = await http.get(`/api/cashbill/google/receipt/${receiptNum}?corpNum=${Number(searchInfo.corpNum)}`);
    
    if(res.data.status === "ERROR") {
      setSuccess(false);
      setData({
        errMessage: res.data.message
      })
      return;
    }
    setData(res.data.data);
    setSuccess(true);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCBLength = async (corpNum, setTempDataLength) => {
  try {
    const res = await http.get(`/api/cashbill/upload/temp/length/${corpNum}`);
    setTempDataLength(res.data.data.CBUploadLength);
    return res.data;
  } catch (err) {
    console.log("getCBLength Err : ", err)
    alertFun(err);
    
  }
};

export const deleteCBUpload = async (corpNum, sortedExcelFile, setModal, modal, setAPIProcess) => {
  try {

    setModal((prevState) => {
      return  {
        ...prevState,
        msg1: "삭제를 진행 중입니다.",
        step: null,
      };
    });

    setAPIProcess({
      isLoading: true, 
      progress: 0
    });

    const toDeleteReceiptNums = sortedExcelFile.map(item => item.receiptNum);  
    const res = await http.post(`/api/cashbill/upload/temp/delete/${corpNum}`, toDeleteReceiptNums);

    setAPIProcess((prevState) => {
      const newProcess = {...prevState, progress: 100};
      return newProcess;
    });
    await new Promise(resolve => setTimeout(resolve, 1000)); // 사용자에게 프로그래스 진행바를 보여주기

    setModal((prevState) => {
      return  {
        ...prevState,
        msg1: "성공적으로 삭제하였습니다.",
        step: 3,
      };
    });
   
    return res.data;
  } catch (err) {
    console.log("deleteCBUpload Err : ", err)
    alertFun(err);
  
  }
};

export const revertToCBUpload = async (corpNum, failCBdata, setModal, modal, setAPIProcess) => {
  
  console.log("revertToCBUpload failCBdata : ", failCBdata);
  try {

    setModal((prevState) => {
      return  {
        ...prevState,
        msg1: "재발급대상으로 전환 중입니다.",
        step: null,
      };
    });

    setAPIProcess({
      isLoading: true, 
      progress: 0
    });

    const toRevertMgtKeys = failCBdata.map(item => item.mgtKey);  
    console.log("revertToCBUpload toRevertMgtKeys : ", toRevertMgtKeys)
    const res = await http.get(`/api/cashbill/revert/bulk/${corpNum}`, toRevertMgtKeys);
    console.log("revertToCBUpload res : ", res)

    setAPIProcess((prevState) => {
      const newProcess = {...prevState, progress: 100};
      return newProcess;
    });
    await new Promise(resolve => setTimeout(resolve, 1000)); // 사용자에게 프로그래스 진행바를 보여주기

    setModal((prevState) => {
      return  {
        ...prevState,
        msg1: "성공적으로 재발급하였습니다.",
        step: 3,
      };
    });
   
    return "총 "+ res.successRevertCnt + "건을 재발급하였습니다.";
  } catch (err) {
    console.log("revertToCBUpload Err : ", err)
    alertFun(err);
  
  }
};

export const revertToCBUpload2 = async (corpNum, failCBdata, setModal, modal, setAPIProcess) => {
  
  console.log("revertToCBUpload2 failCBdata : ", failCBdata);
  try {
  
    const mgtKeyList = [];

    setModal((prevState) => {
      return {
        ...prevState,
        msg1: "재발급대상으로 전환 중입니다.",
        msg2: "",
        step: null,
        warning: "※ 등록이 원할하지 않을 경우, [발급결과조회] 메뉴에서 확인 후 다시 시도하시기 바랍니다.",
      };
    });

    setAPIProcess({
      isLoading: true, 
      progress: 0
    });

    const toRevertMgtKeys = failCBdata.map(item => item.mgtKey);  
    console.log("revertToCBUpload toRevertMgtKeys : ", toRevertMgtKeys);

    for(let i in toRevertMgtKeys){
      
      console.log("toRevertMgtKeys[i] = ", toRevertMgtKeys[i]);
      const mgtKey = toRevertMgtKeys[i];
      let delRes = await http.get(`/api/cashbill/revert/delete/${corpNum}/${mgtKey}`);

      // 요청 지연 (For : 팝필 - 개별 현금영수증 처리상태는 접수상태(txState)가 완료(2) 시 반환됨으로 대기 시간 필요)
      await new Promise(resolve => setTimeout(resolve, 1600)); // test
      console.log("revertDelete delRes ===", delRes);
      
      if(delRes.data.status === "ERROR") {
        setAPIProcess({
          isLoading: false, 
          progress: 0
        });
        setModal((prevState) => {
          return {
            ...prevState,
            msg1: "",
            msg2: "윌러스에 문의해주세요.",
            warning: "[에러발생] " +delRes.data.message,
            step: 3,
          };
        });
        return { 
          mgtKeyList : []
        };
      } 

      if(delRes.data.status === "SUCCESS") {
        setAPIProcess({
          isLoading: true, 
          progress: 50
        });
  
        mgtKeyList.push(delRes.data.mgtKey);
      }
    }

    
    if(mgtKeyList.length > 0){
      let moveRes = await http.post(`/api/cashbill/revert/move/${corpNum}`, mgtKeyList);
      console.log("revertMove moveRes : ", moveRes)

      setModal((prevState) =>{
        return {
          ...prevState,
          step: 3,
          modalType: "",
          msg1 : "현금영수증 재발급대상으로 전환 완료하였습니다.",
          msg2 : "",
          warning: "",
          tableData: { 
            failSavedCnt: "",
            sccessSavedCnt: "",
          }
        };
      });
      setAPIProcess({
        isLoading: false, 
        progress: 0
      });

      return moveRes.data;

    }
  } catch (err) {
    console.log("revertToCBUpload2 Err : ", err)
    alertFun(err);
  
  }
};


export const setCBUpload = async ( pageType, corpNum, excelFile, modal, setModal, setExcelFile, setAPIProcess) => {
      
      try{

          setModal((prevState) => {
            return  {
              ...prevState,
              msg1: "현금영수증을 임시저장하고 있습니다.",
              msg2: "데이터의 용량에 따라 시간이 소요될 수 있습니다.",
              step: null,
            };
          });

          setAPIProcess({
            isLoading: true, 
            progress: 0
          });

          let res;
          if(pageType === '승인') {
            res = await http.post(`/api/cashbill/upload/temp/${corpNum}`, excelFile);
          } else {
            res = await http.post(`/api/cashbill/upload/temp/refund/${corpNum}`, excelFile);
          }
          
          setAPIProcess((prevState) => {
            const newProcess = {...prevState, progress: 100};
            return newProcess;
          });

          await new Promise(resolve => setTimeout(resolve, 1200)); // 사용자에게 프로그래스 진행바를 보여주기
          setAPIProcess({  isLoading: false, progress: 0 });

          if(pageType === '승인' &&  Number(res.data.data.sccessSavedCnt) === 0) { // 모든 건이 중복인 경우

            setModal((prevState) => {
              return  {
                ...prevState,
                step: 3,
                modalType: "table",
                msg1 : "작성된 모든 데이터가 이미 등록된 데이터 입니다.",
                msg2 : "",
                tableData: res.data.data
              };
            });
           
            setExcelFile([]);

          } else if(pageType === '취소' &&  Number(res.data.data.failSavedCnt) > 0) { // CBRecord에 등록된 취소 거래가 없을 때
            setModal((prevState) => {
              return  {
                ...prevState,
                step: 3,
                modalType: "table",
                msg1 : "중복 취소발급 건 또는",
                msg2 : "발급되지 않은 승인거래의 취소발급을 제외하고 임시저장 합니다.",
                tableData: res.data.data
              };
            });

            const savedFail = res.data.data.data;
            
            savedFail.forEach(element => {
              element.ErrMsg = "중복취소발급 건이거나 해당 메모에 대한 승인발급 건이 없습니다."
            });
            setExcelFile([...savedFail]);

          } else {  // 성공/실패 데이터(중복 데이터)

            setModal((prevState) => {
              return  {
                ...prevState,
                step: 2,
                modalType: "table",
                msg1 : "일괄등록 문서의 임시저장이 완료 되었습니다.",
                msg2 : "개별발급 페이지로 이동하시겠습니까?",
                tableData: {
                  sccessSavedCnt : res.data.data.sccessSavedCnt,
                  failSavedCnt :  res.data.data.failSavedCnt > 0 ?   "중복(" + res.data.data.failSavedCnt + ")" :  res.data.data.failSavedCnt
                }
              };
            });

            const savedFail = res.data.data.data;            
            savedFail.forEach(element => {
              element.ErrMsg = "중복 발급 건 입니다."
            });
            setExcelFile([...savedFail]);
            
          }


          return res;
        } catch(err) {
          console.log("cbBulkRegistOrRevokeIssue Err : ", err)

            setModal((prevState) => {
              return  {
                ...prevState,
                step: 3,
                modalType: "text",
                msg1 : "응답이 지연되었습니다.",
                msg2 : "다시 실행해주세요.",
              };
            });

            alertFun(err);
    
         }
}

export const alertFun = (err) => {
  console.log("alertFun err.response ---", err.response);

  if(err.response.data.type === "토큰만료") {
    window.location.replace("/login")
    alert(err.response.data.message);
    removeJWT();
    return;
  } 

  if(err.response.data.type === "FORBIDDEN") {
    alert(err.response.data.message);
    return;
  } 


  alert(err);
}


export const updateCBUpload = async ( corpNum, updateList, modal, setModal, setAPIProcess) => {
      
  try{

      setModal((prevState) => {
        return  {
          ...prevState,
          msg1: "현금영수증 수정사항을 저장하고 있습니다.",
          msg2: "데이터의 용량에 따라 시간이 소요될 수 있습니다.",
          step: null,
        };
      });

      setAPIProcess({
        isLoading: true, 
        progress: 0
      });

      let res = await http.post(`/api/cashbill/upload/update/${corpNum}`, updateList);
      console.log('res =  ', res);
    
      
      return res;
    } catch(err) {
      console.log("cbBulkRegistOrRevokeIssue Err : ", err)

        setModal((prevState) => {
          return  {
            ...prevState,
            step: 3,
            modalType: "text",
            msg1 : "응답이 지연되었습니다.",
            msg2 : "다시 실행해주세요.",
          };
        });

        alertFun(err);

     }
}