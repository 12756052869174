import { createSlice } from '@reduxjs/toolkit'; // reducer 로직과 action을 단순화 시킴

let initialState = {
  isLoading : false
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState ,
  reducers: {
    setLoading:(state, { payload }) => {
        state.isLoading = payload;
    },
}
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;