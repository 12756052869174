import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import FallBack from "component/common/fallBack/FallBack";
import ProtectedRoute from "component/ProtectedRoute";


const Login = React.lazy(() => import("./page/login/Login"));
const Index = React.lazy(() => import("./page/index/Index"));

// User
const MyPage = React.lazy(() => import("./page/user/mypage/MyPage"));
const UserSearch = React.lazy(() => import("./page/user/search/UserSearch"));

// CashBill
const CBSearch = React.lazy(() => import("./page/cashBill/CBSearch/CBSearch"));
const CBLog = React.lazy(() => import("./page/cashBill/CBLog/CBLog"));
const CBTemp = React.lazy(() => import("./page/cashBill/CBTemp/CBTemp"));
const CBOneRegister = React.lazy(() => import("./page/cashBill/CBOneRegister/CBOneRegister"));
const CBBulkRegisterNormal = React.lazy(() => import("./page/cashBill/CBBulkRegisterNormal/CBBulkRegisterNormal"));
const CBBulkRegisterCancel = React.lazy(() => import("./page/cashBill/CBBulkRegisterCancel/CBBulkRegisterCancel"));
const CBCustomerCheckById = React.lazy(() => import("./page/cashBill/CBCustomerCheckById/CBCustomerCheckByIdWrap"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/user/receipt/search/:corpName/:corpNum",
    element: <CBCustomerCheckById />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [{
      // errorElement: <ErrorPage/>,
      children: [
        {
          index: true,
          element: <Index />,
        },
        {
          path: "mypage",
          element: <MyPage />,
        },
        {
          path: "user/search",
          element: <UserSearch />,
        },
        {
          path: "cashbill/temp",
          element: <CBTemp />,
        },
        {
          path: "cashbill/write/normal",
          element: <CBBulkRegisterNormal />,
        },
        {
          path: "cashbill/write/cancel",
          element: <CBBulkRegisterCancel />,
        },
        {
          path: "cashbill/write/one",
          element: <CBOneRegister />,
        },
        {
          path: "cashbill/search",
          element: <CBSearch />,
        },
        {
          path: "cashbill/log",
          element: <CBLog />,
        },
      ]
    }]
  },
]);

export default function App() {
  return (
    <Suspense fallback={FallBack()}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
