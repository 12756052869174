import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutThunk } from "store/reducer/user/userThunk";
// import {  toggleFullscreen } from "./haeder"

// CSS파일 위치 : assets/css/sidebar.css
export default function Header({ handleHambugerMenu }) {
  const { userId, corpName } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const [ myControlShow, setMyControlShow ] = useState(false);
  // const [ isFullscreen, setIsFullscreen ] = useState(false);

  const handleLogout = async () => {
    await dispatch(logoutThunk({ navigate })).unwrap();
   }

  

  return (
    <div>
      <header className="position-fixed  color-white " 
        style={{ height: process.env.REACT_APP_API_URL === "https://test.wnts.co.kr"  ? "80px": "60px" }}
      >
        {  process.env.REACT_APP_API_URL === "https://test.wnts.co.kr"  ?
        <div style={{ textAlign: "center" }}>
          <div style={{ backgroundColor: "#5c9bde", color: "#fff", fontSize: "18px" }}>테스트 환경</div>
        </div> : null }
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {/* <span className="d-inline-block" onClick={handleHambugerMenu}>
              <FontAwesomeIcon
                icon={faBars}
                className="toggle d-block font-24 color-dark mg-l20"
              />
            </span> */}
            <p className="d-none d-sm-block color-dark pd-l20 font-bold">
              {/* NS 해더 */}
            </p>
          </div>
          <div className="d-flex align-items-center">
            <div className="mg-r20 color-mid">
              <span className="d-block eng-700 font-10">WNTS</span>
              <span className={"d-block logo_under-border"}><img src="/assets/icon/under_border.png" alt="border" /></span>
            </div>
            <ul id="header-icons-wrap" className="user-menu d-flex align-items-center justify-content-center pd-r20">
              <li className="nav-item-box cursor-pointer mg-r15">
                  <Link  className="nav-item-bg d-block"  to={"/"}>
                    <i className="las la-home color-mid  "></i>
                  </Link>
              </li>
              <li
                className=" user-icon-box position-relative d-flex align-items-center "
                onMouseEnter={() => setMyControlShow(true)}
                onMouseLeave={() => setMyControlShow(false)}
              >
                <div style={{  }} className="user-info-wrap d-flex align-items-center ">
                  <div className="nav-item-box">
                    <p className="nav-item-bg"><i className="las la-user-tie color-mid font-26"></i></p>
                  </div>
                  <div className="user-info">
                    <p>{ userId }</p>
                    <p>{ corpName }- Administrator</p>
                  </div>
                </div>
                <ul className={`mycontrol-box box_down_shadow ${myControlShow ? "" : "d-none" }`}>
                  <li className="cursor-pointer">
                      <Link className="color-dark d-block  w-100 h-100" to={"/mypage"}>
                        <i className="las la-user-cog font-20 color-mid mg-r5"></i> MyPage
                      </Link>
                  </li>
                  <li className=" cursor-pointer color-dark" onClick={handleLogout} >
                    <p className="w-100 h-100"><i className="las la-sign-out-alt color-red font-20 "></i>  Logout</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}
